import React, { useEffect, useRef } from 'react';
import { useTable, useSortBy, useFilters, useGlobalFilter, createColumnHelper } from 'react-table';
import matchSorter from 'match-sorter';
import './styles.scss';
console.log('createColumnHelper1', createColumnHelper)
function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
  customOnChange = null,
  customDefaultLabel,
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach(row => {
      // if (id === 'redFlag') {
      //   options.add(row.values[id].label);
      //   return [...options.values()];
      // }

      options.add(row.values[id]);
    });

    if (id === 'finalizeStatus') {
      var collator = new Intl.Collator(undefined, {
        numeric: true,
        sensitivity: 'base',
      });
      var sortedAlphaNumericOptions = [...options.values()];
      return sortedAlphaNumericOptions.sort(collator.compare).reverse();
    }

    return [...options.values()];
  }, [id, preFilteredRows]);
  const getlabel = option => {
    if (!option || option === ' ') {
      return '<Empty>';
    }
    return option;
  };

  // Render a multi-select box
  return (
    <select
      onClick={e => {
        // prevents the triggering of the sort column click event from firing when focusing on the filter input
        e.stopPropagation();
      }}
      className="form-control select-list"
      value={filterValue}
      onChange={e => {
        if (customOnChange) {
          customOnChange(e);
          return;
        }
        const value = e.target.value === '<Empty>' ? null : e.target.value;
        setFilter(value || undefined);
      }}
    >
      <option value="">{customDefaultLabel || 'All'}</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {getlabel(option)}
        </option>
      ))}
    </select>
  );
}
function NumberRangeColumnFilter({
  column: { filterValue = [], preFilteredRows, setFilter, id },
}) {
  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;

    preFilteredRows.forEach(row => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  // console.log('min', min);
  // console.log('max', max);
  return (
    <div className="d-flex align-items-center">
      <input
        className="form-control min-max-input"
        value={filterValue[0] || ''}
        type="number"
        onClick={e => {
          // prevents the triggering of the sort column click event from firing when focusing on the filter input
          e.stopPropagation();
        }}
        onChange={e => {
          const val = e.target.value;
          setFilter((old = []) => [
            val ? parseInt(val, 10) : undefined,
            old[1],
          ]);
        }}
        placeholder={`Min (${min})`}
      />
      <span className="mx-1">to</span>
      <input
        className="form-control min-max-input"
        value={filterValue[1] || ''}
        type="number"
        onClick={e => {
          // prevents the triggering of the sort column click event from firing when focusing on the filter input
          e.stopPropagation();
        }}
        onChange={e => {
          const val = e.target.value;
          setFilter((old = []) => [
            old[0],
            val ? parseInt(val, 10) : undefined,
          ]);
        }}
        placeholder={`Max (${max})`}
      />
    </div>
  );
}

function SliderColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the min and max
  // using the preFilteredRows

  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach(row => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <>
      <input
        className="form-control"
        type="range"
        min={min}
        max={max}
        value={filterValue || min}
        onChange={e => {
          setFilter(parseInt(e.target.value, 10));
        }}
      />
      <button onClick={() => setFilter(undefined)}>Off</button>
    </>
  );
}

const ReactTable = ({
  children,
  data,
  columns,
  hookFunction,
  initialState,
}) => {
  function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }) {
    const count = preFilteredRows.length;

    return (
      <input
        className="form-control"
        value={filterValue || ''}
        onClick={e => {
          // prevents the triggering of the sort column click event from firing when focusing on the filter input
          e.stopPropagation();
        }}
        onChange={e => {
          setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }}
        placeholder={`Search ${count} records...`}
      />
    );
  }
  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );
  function fuzzyTextFilterFn(rows, id, filterValue) {
    return matchSorter(rows, filterValue, { keys: [row => row.values[id]] });
  }
  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );
  const tableInstance = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      autoResetFilters: false,
      initialState,
    },

    useFilters,
    useGlobalFilter,
    useSortBy,
    hooks => {
      hookFunction && hookFunction(hooks);
    }
  );

  return <>{children(tableInstance)}</>;
};

export {
  ReactTable,
  SliderColumnFilter,
  NumberRangeColumnFilter,
  SelectColumnFilter,
  createColumnHelper
};
