import React from 'react';
import Cookies from 'js-cookie';
import moment from 'moment-timezone';
import { keyToLabel } from 'src/util';
import { NumberRangeColumnFilter, SelectColumnFilter } from 'src/components/ReactTable';
import { ChevronUp, ChevronDown } from 'react-feather';

export const getToken = () => {
  let token = new URLSearchParams(window.location.hash.substr(1)).get('token');
  if (token) {
    Cookies.set('token', token);
    window.location.replace(window.location.href.replace(/\#.*/, ''));
  } else {
    token = Cookies.get('token');
  }
  return token;
};

export const formatColumnData = (reportData, columnDefinitions, CustomCell) => {
  const columns = [];
  const formattedData =
    reportData &&
    reportData[0] &&
    Object.keys(reportData[0]).reduce((acc, item, index) => {
      if (item.match(/_hidden$/)) return acc;
      const filterOptions = {};

      if (typeof reportData[0][item] === 'number') {
        filterOptions.Filter = NumberRangeColumnFilter;
        filterOptions.filter = 'between';
      } else if (useSelectFilter(item, reportData) || item === 'property') {
        filterOptions.Filter = SelectColumnFilter;
        filterOptions.filter = 'includes';
      }

      let cellData = {
        Header:
          (columnDefinitions || {})[item]?.headerLabel || keyToLabel(item),
        columnDefinition: (columnDefinitions || {})[item],
        accessor: item,
        Cell: CustomCell,
        ...filterOptions,
      };
      console.log('item', item)
      console.log('columnDefinitions', columnDefinitions)
      console.log('(columnDefinitions || {})[item]?.groupHeaderLabel', (columnDefinitions || {})[item]?.groupHeaderLabel)
      if ((columnDefinitions || {})[item]?.groupHeaderLabel) {
        let groupFound = columns.find(group => {
          return group.Header == (columnDefinitions || {})[item]?.groupHeaderLabel
        })
        console.log('groupFound', groupFound)
        if (!groupFound) {
          groupFound = {
            Header: (columnDefinitions || {})[item]?.groupHeaderLabel,
            columns: []
          }
          columns.push(groupFound);
        }
        groupFound.columns.push(cellData)
        
      } else {
        columns.push(cellData)
      }



      
      return acc;
    }, []);
console.log('columns', columns)
  return reportData ? columns || [] : [];
};

export const getCellClassNames = cell => {
  const classNames = [];

  if (cell.column.columnDefinition?.classNames) {
    const classNames = generateTemplateString(
      cell.column.columnDefinition?.classNames
    )(cell.row.original);
    return classNames;
  }
  if (typeof cell.value === 'number') {
    classNames.push('text-right');
  }

  return classNames.join(' ');
};

export const generateSortingIndicator = column => {
  return column.isSorted ? (
    column.isSortedDesc ? (
      <div>
        <ChevronDown />
      </div>
    ) : (
      <div>
        <ChevronUp />
      </div>
    )
  ) : (
    <div className="d-flex flex-column">
      <ChevronUp />
      <ChevronDown style={{ marginTop: '-10px' }} />
    </div>
  );
};

export const useSelectFilter = (key, recordset) => {
  const unique = [...new Set(recordset.map(x => x[key]))];
  return unique.length < 7;
};

export const generateTemplateString = (function() {
  var cache = {};

  function generateTemplate(template) {
    var fn = cache[template];

    if (!fn) {
      var sanitized = template
        .replace(/\$\{([\s]*[^;\s\{]+[\s]*)\}/g, function(_, match) {
          return `\$\{map.${match.trim()}\}`;
        })
        .replace(/(\$\{(?!map\.)[^}]+\})/g, '');

      fn = Function('map', `return \`${sanitized}\``);
    }

    return fn;
  }

  return generateTemplate;
})();